import React, { useState, useEffect } from 'react';
import apiService from '../Services/apiService';

const IpApiComponent = () => {
  const [locationData, setLocationData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getLocationData = async () => {
      try {
        const data = await apiService.fetchLocationData();
        setLocationData(data);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    getLocationData();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div>
      <h1>Your Location Information</h1>
      {locationData && (
        <ul>
          <li>IP: {locationData.query}</li>
          <li>Country: {locationData.country}</li>
          <li>Region: {locationData.regionName}</li>
          <li>City: {locationData.city}</li>
          <li>ZIP: {locationData.zip}</li>
          <li>Latitude: {locationData.lat}</li>
          <li>Longitude: {locationData.lon}</li>
          <li>ISP: {locationData.isp}</li>
        </ul>
      )}
    </div>
  );
};

export default IpApiComponent;
