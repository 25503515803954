import React, { useState, useEffect } from 'react';

const IpApiComponent = () => {
  const [locationData, setLocationData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchLocationData = async () => {
      try {
        const response = await fetch('https://pro.ip-api.com/json/?key=UNZGNB1ewdjlcde');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setLocationData(data);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchLocationData();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div>
      <h1>Your Location Information</h1>
      {locationData && (
        <ul>
          <li>IP: {locationData.query}</li>
          <li>Country: {locationData.country}</li>
          <li>Region: {locationData.regionName}</li>
          <li>City: {locationData.city}</li>
          <li>ZIP: {locationData.zip}</li>
          <li>Latitude: {locationData.lat}</li>
          <li>Longitude: {locationData.lon}</li>
          <li>ISP: {locationData.isp}</li>
        </ul>
      )}
    </div>
  );
};

export default IpApiComponent;
