import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moroccoCities from '../morocco_cities.json';



const Weather = () => {
  const [weatherData, setWeatherData] = useState(null);
  const [city, setCity] = useState('');
  const [userCity, setUserCity] = useState('');
  const [selectedCity, setSelectedCity] = useState('');


  const weatherApiKey = '2be185f1c14565df62f7ccdf08da8a95'; // Replace with your OpenWeatherMap API key
  const ipApiKey = 'UNZGNB1ewdjlcde'; // Replace with your IP-API key

  // Get user's city based on IP address
  useEffect(() => {
    const fetchUserCity = async () => {
      try {
        const response = await axios.get(`https://pro.ip-api.com/json/?key=${ipApiKey}`);
        setUserCity(response.data.city);
        getWeather(response.data.city);
      } catch (error) {
        console.error('Error fetching the user city', error);
      }
    };

    fetchUserCity();
  }, []);




  // Fetch weather data for the given city
  const getWeather = async (cityName) => {
    try {
      const response = await axios.get(
        `https://api.openweathermap.org/data/2.5/weather?q=${cityName},MA&appid=${weatherApiKey}&units=metric`
      );
      setWeatherData(response.data);
    } catch (error) {
      console.error('Error fetching the weather data', error);
    }
  };




  const handleCityChange = (e) => {
    setCity(e.target.value);
    getWeather(e.target.value);
  };




  return (
    <div>
      <h1>Weather App</h1>
      <select value={city} onChange={handleCityChange} className="form-select">
        <option value="">Select a city</option>
        {moroccoCities.map((cityObj, index) => (
          <option key={index} value={cityObj.name}>
            {cityObj.name}
          </option>
        ))}
      </select>
      <br></br>
      {weatherData && (
        <div>
          <h2>{weatherData.name}, {weatherData.sys.country}</h2>
          <p>Coordinates: Lat {weatherData.coord.lat}, Lon {weatherData.coord.lon}</p>
          <p>Weather: {weatherData.weather[0].main} ({weatherData.weather[0].description})</p>
          <img src={`http://openweathermap.org/img/wn/${weatherData.weather[0].icon}.png`} alt="weather icon" />
          <p>Temperature: {weatherData.main.temp}°C</p>
          <p>Feels like: {weatherData.main.feels_like}°C</p>
          <p>Temperature Max: {weatherData.main.temp_max}°C</p>
          <p>Temperature Min: {weatherData.main.temp_min}°C</p>
          <p>Pressure: {weatherData.main.pressure} hPa</p>
          <p>Humidity: {weatherData.main.humidity}%</p>
          <p>Sea Level Pressure: {weatherData.main.sea_level} hPa</p>
          <p>Ground Level Pressure: {weatherData.main.grnd_level} hPa</p>
          <p>Visibility: {weatherData.visibility} meters</p>
          <p>Wind Speed: {weatherData.wind.speed} m/s</p>
          <p>Wind Direction: {weatherData.wind.deg}°</p>
          <p>Cloudiness: {weatherData.clouds.all}%</p>
          <p>Sunrise: {new Date(weatherData.sys.sunrise * 1000).toLocaleTimeString()}</p>
          <p>Sunset: {new Date(weatherData.sys.sunset * 1000).toLocaleTimeString()}</p>
        </div>
      )}

      {!city && weatherData && (
        <p>Showing weather for your current location: {userCity}</p>
      )}
    </div>
  );
};

export default Weather;
