import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { format, addDays, subDays, getMonth, getYear } from 'date-fns';
import { useLocation, useNavigate } from 'react-router-dom';
import './ispasn2.css';

const PrayerTimes = () => {




  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const initialDateParam = queryParams.get('date') || format(new Date(), 'dd-MM-yyyy');








  const [dateParam, setDateParam] = useState(initialDateParam);
  const [data, setData] = useState(null);
  const [locationData, setLocationData] = useState({ city: '', country: '' });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [previousDay, setPreviousDay] = useState('');
  const [nextDay, setNextDay] = useState('');
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());











  useEffect(() => {



    const fetchLocationAndPrayerTimes = async () => {
      
      try {
        // Fetch location data
        const locationResponse = await fetch('https://pro.ip-api.com/json/?key=UNZGNB1ewdjlcde');

        const locationData = await locationResponse.json();

        if (!locationResponse.ok) 
        {
          throw new Error('Location data not available');
        }


        const { city, country } = locationData;

        setLocationData({ city, country });

        const fetchPrayerTimes = async (month, year) => {

        const apiUrl = `https://api.aladhan.com/v1/calendarByCity/${year}/${month}?city=${city}&country=${country}`;


          try 
          {
            console.log(`Fetching prayer times for ${month}-${year}`);
            const response = await axios.get(apiUrl);


            if (response.status === 200) 
              
            {
              const prayerTimes = response.data.data;
              const prayerTimesForDate = prayerTimes.find(item => item.date.gregorian.date);

              return prayerTimesForDate || null;
            } 

            else 
            {
              return null;
            }

          } 
          catch (error) 
          {
            console.error('Error fetching prayer times:', error);
            return null;
          }

        };
        
        

        let prayerTimesData = await fetchPrayerTimes(currentMonth, currentYear);

        // Try the next month if current month is not available
        if (!prayerTimesData) {
          const nextMonth = currentMonth === 12 ? 1 : currentMonth + 1;
          const nextYear = currentMonth === 12 ? currentYear + 1 : currentYear;
          console.log(`Trying next month: ${nextMonth}-${nextYear}`);

          prayerTimesData = await fetchPrayerTimes(nextMonth, nextYear);

          if (prayerTimesData) {
            setDateParam(dateParam);
            setCurrentMonth(nextMonth);
            setCurrentYear(nextYear);
            navigate(`?date=${dateParam}`);
          } else {
            throw new Error('Could not retrieve prayer times for the selected date or the next month.');
          }

        }

        // Calculate previous and next days
        const currentDate = new Date(dateParam.split('-').reverse().join('-'));
        setPreviousDay(format(subDays(currentDate, 1), 'dd-MM-yyyy'));
        setNextDay(format(addDays(currentDate, 1), 'dd-MM-yyyy'));

        setData(prayerTimesData);
      } catch (error) {
        console.error('Error in fetchLocationAndPrayerTimes:', error);
        setError('Could not retrieve prayer times for the selected date or the next month.');
      } finally {
        setLoading(false);
      }
    };

    fetchLocationAndPrayerTimes();
  }, [dateParam, currentMonth, currentYear, navigate]);






















  const handleDateChange = (newDate) => {
    const newDateObj = new Date(newDate.split('-').reverse().join('-'));
    const newMonth = newDateObj.getMonth() + 1;
    const newYear = newDateObj.getFullYear();

    if (newMonth !== currentMonth || newYear !== currentYear) {
      setCurrentMonth(newMonth);
      setCurrentYear(newYear);
    }
    setDateParam(newDate);
    navigate(`?date=${newDate}`);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="container mt-4">
    <div className="card">
      <h1>Prayer Times for {locationData.city}, {locationData.country}</h1>
      {data === null ? (
        <p>Could not retrieve prayer times for the selected date. Please try again later.</p>
      ) : (
        <>
        <div class="prayer-times">
          <div class="success">Date : {data.date?.readable || 'N/A'}</div>
          <div>Fajr : {data.timings?.Fajr || 'N/A'}</div>
          <div>Sunrise : {data.timings?.Sunrise || 'N/A'}</div>
          <div>Dhuhr : {data.timings?.Dhuhr || 'N/A'}</div>
          <div>Asr : {data.timings?.Asr || 'N/A'}</div>
          <div>Maghrib : {data.timings?.Maghrib || 'N/A'}</div>
          <div>Isha : {data.timings?.Isha || 'N/A'}</div>
          <div>hijri : {data.date.hijri?.date || 'not found'}</div>
          <div>hijri mounth : {data.date.hijri?.month.en || 'not found'}</div>


        </div>



          <div className="mt-3 text-center">
            {previousDay && <button class="btn btn-success" onClick={() => handleDateChange(previousDay)}>Previous Day</button>}
            {nextDay && <button onClick={() => handleDateChange(nextDay)} className="ms-3 btn btn-success">Next Day</button>}
          </div>
          <div className="mt-4 text-center">
            <p><strong>API URL:</strong> {`https://api.aladhan.com/v1/calendarByCity/${currentYear}/${currentMonth}?city=${locationData.city}&country=${locationData.country}`}</p>
          </div>
        </>
      )}
    </div>
    </div>
  );
};

export default PrayerTimes;
