import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { format, addDays, subDays } from 'date-fns';
import { useLocation, useNavigate } from 'react-router-dom';
import './ispasn2.css';

const PrayerTimes = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const initialDateParam = queryParams.get('date') || format(new Date(), 'dd-MM-yyyy');

  const [dateParam, setDateParam] = useState(initialDateParam);
  const [data, setData] = useState(null);
  const [locationData, setLocationData] = useState({ city: '', country: '' });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [previousDay, setPreviousDay] = useState('');
  const [nextDay, setNextDay] = useState('');
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [selectedCity, setSelectedCity] = useState('');

  useEffect(() => {
    const fetchLocationAndPrayerTimes = async () => {
      try {
        // Fetch location data
        const locationResponse = await fetch('https://pro.ip-api.com/json/?key=UNZGNB1ewdjlcde');
        const locationData = await locationResponse.json();

        if (!locationResponse.ok) {
          throw new Error('Location data not available');
        }

        const { city, country } = locationData;
        setLocationData({ city, country });
        setSelectedCity(city);

        // Fetch prayer times for the detected city
        await fetchPrayerTimes(city, country);
      } catch (error) {
        console.error('Error in fetchLocationAndPrayerTimes:', error);
        setError('Could not retrieve location data or prayer times.');
      } finally {
        setLoading(false);
      }
    };

    fetchLocationAndPrayerTimes();
  }, []);

  const fetchPrayerTimes = async (city, country) => {
    try {
      const apiUrl = `https://api.aladhan.com/v1/calendarByCity/${currentYear}/${currentMonth}?city=${city}&country=${country}`;
      const response = await axios.get(apiUrl);
      if (response.status === 200) {
        const prayerTimes = response.data.data;
        const prayerTimesForDate = prayerTimes.find(item => item.date.gregorian.date === dateParam);
        setData(prayerTimesForDate || null);

        // Calculate previous and next days
        const currentDate = new Date(dateParam.split('-').reverse().join('-'));
        setPreviousDay(format(subDays(currentDate, 1), 'dd-MM-yyyy'));
        setNextDay(format(addDays(currentDate, 1), 'dd-MM-yyyy'));
      } else {
        throw new Error('Could not retrieve prayer times.');
      }
    } catch (error) {
      console.error('Error fetching prayer times:', error);
      setError('Could not retrieve prayer times for the selected date.');
    }
  };

  const handleDateChange = (newDate) => {
    const newDateObj = new Date(newDate.split('-').reverse().join('-'));
    const newMonth = newDateObj.getMonth() + 1;
    const newYear = newDateObj.getFullYear();

    if (newMonth !== currentMonth || newYear !== currentYear) {
      setCurrentMonth(newMonth);
      setCurrentYear(newYear);
    }
    setDateParam(newDate);
    navigate(`?date=${newDate}`);
    fetchPrayerTimes(selectedCity, locationData.country);
  };

  const handleCityChange = (event) => {
    const newCity = event.target.value;
    setSelectedCity(newCity);
    fetchPrayerTimes(newCity, locationData.country);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="container mt-4">
      <div className="card">
        <h1>Prayer Times for {selectedCity}, {locationData.country}</h1>
        <div className="mb-3">
          <label htmlFor="cityInput" className="form-label">Enter City:</label>
          <input
            type="text"
            id="cityInput"
            className="form-control"
            value={selectedCity}
            onChange={handleCityChange}
            placeholder="Enter city name"
          />
        </div>
        {data === null ? (
          <p>Could not retrieve prayer times for the selected date. Please try again later.</p>
        ) : (
          <>
            <div className="prayer-times">
              <div className="success">Date: {data.date?.readable || 'N/A'}</div>
              <div>Fajr: {data.timings?.Fajr || 'N/A'}</div>
              <div>Sunrise: {data.timings?.Sunrise || 'N/A'}</div>
              <div>Dhuhr: {data.timings?.Dhuhr || 'N/A'}</div>
              <div>Asr: {data.timings?.Asr || 'N/A'}</div>
              <div>Maghrib: {data.timings?.Maghrib || 'N/A'}</div>
              <div>Isha: {data.timings?.Isha || 'N/A'}</div>
            </div>
            <div className="mt-3 text-center">
              {previousDay && <button className="btn btn-success" onClick={() => handleDateChange(previousDay)}>Previous Day</button>}
              {nextDay && <button onClick={() => handleDateChange(nextDay)} className="ms-3 btn btn-success">Next Day</button>}
            </div>
            <div className="mt-4 text-center">
              <p><strong>API URL:</strong> {`https://api.aladhan.com/v1/calendarByCity/${currentYear}/${currentMonth}?city=${selectedCity}&country=${locationData.country}`}</p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PrayerTimes;