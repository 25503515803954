import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Weather = () => {
  const [weatherData, setWeatherData] = useState(null);
  const [city, setCity] = useState('');
  const [userCity, setUserCity] = useState('');

  const weatherApiKey = '0f5ad6c1d14ca595ddf930be1315db15'; // Replace with your Weatherstack or OpenWeatherMap API key
  const ipApiKey = 'UNZGNB1ewdjlcde'; // Replace with your IP-API key

  // Get user's city based on IP address
  useEffect(() => {
    const fetchUserCity = async () => {
      try {
        const response = await axios.get(
          `https://pro.ip-api.com/json/?key=${ipApiKey}`
        );
        setUserCity(response.data.city);
        getWeather(response.data.city);
      } catch (error) {
        console.error('Error fetching the user city', error);
      }
    };

    fetchUserCity();
  }, []);

  // Fetch weather data for the given city
  const getWeather = async (cityName) => {
    try {
      const response = await axios.get(
        `http://api.weatherstack.com/current?access_key=${weatherApiKey}&query=${cityName}`
      );
      setWeatherData(response.data);
    } catch (error) {
      console.error('Error fetching the weather data', error);
    }
  };

  // Handle city input change
  const handleInputChange = (e) => {
    setCity(e.target.value);
  };

  // Handle search button click
  const handleSearch = () => {
    getWeather(city);
  };

  return (
    <div>
      <h1>Weather App</h1>
      <input
        type="text"
        placeholder="Enter city"
        value={city}
        onChange={handleInputChange}
      />
      <button onClick={handleSearch}>Search</button>

      {weatherData && weatherData.current && (
        <div>
          <h2>{weatherData.location.name}, {weatherData.location.country}</h2>
          <img src={weatherData.current.weather_icons[0]} alt="weather icon" />
          <p>Temperature: {weatherData.current.temperature}°C</p>
          <p>Weather: {weatherData.current.weather_descriptions[0]}</p>
          <p>Wind: {weatherData.current.wind_speed}</p>
          <p>wind degree: {weatherData.current.wind_degree}</p>
          <p>pressure: {weatherData.current.pressure}</p>

        </div>
      )}

      {!city && weatherData && weatherData.location && (
        <p>Showing weather for your current location: {userCity}</p>
      )}
    </div>
  );
};

export default Weather;
