import React, { useState, useEffect } from 'react';

const NearbyMosques = () => {
  const [mosques, setMosques] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Replace with your latitude and longitude
  const latitude =  33.5776794;
  const longitude = -7.63969;

  useEffect(() => {
    // Function to fetch mosques data
    const fetchMosques = async () => {
      try {
        const response = await fetch(
          `https://overpass-api.de/api/interpreter?data=[out:json];node["amenity"="place_of_worship"]["religion"="muslim"](around:5000,${latitude},${longitude});out;`
        );
        const data = await response.json();
        setMosques(data.elements);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchMosques();
  }, [latitude, longitude]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error fetching mosques data: {error.message}</div>;

  return (
    <div>
      <h1>Nearby Mosques</h1>
      <ul>
        {mosques.map((mosque) => (
          <li key={mosque.id}>
            {mosque.tags.name || 'Unnamed mosque'} - Lat: {mosque.lat}, Lon: {mosque.lon}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default NearbyMosques;
