// src/services/apiService.js

import axios from 'axios';

const API_KEY = 'UNZGNB1ewdjlcde';
const BASE_URL = 'https://pro.ip-api.com/json/';

const apiService = {








    
  fetchLocationData: async () => {
    try {
      const response = await axios.get(`${BASE_URL}?key=${API_KEY}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching location data:', error);
      throw error;
    }
  },

  // You can add more API functions here











};

export default apiService;
