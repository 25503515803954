import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { format, addDays, parseISO, differenceInSeconds, isBefore } from 'date-fns';
import { useLocation, useNavigate } from 'react-router-dom';
import './ispasn2.css';
import moroccoCities from '../morocco_cities.json';

const PrayerTimes = () => {
  // Existing states
  const [data, setData] = useState(null);
  const [locationData, setLocationData] = useState({ city: '', country: '', lat: 0, lon: 0 });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedCity, setSelectedCity] = useState('');
  const [countdown, setCountdown] = useState(null);
  const [nextPrayer, setNextPrayer] = useState(null);
  const [mosques, setMosques] = useState([]); // New state for mosques
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());


  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const initialDateParam = queryParams.get('date') || format(new Date(), 'dd-MM-yyyy');
  const [dateParam, setDateParam] = useState(initialDateParam);

  useEffect(() => {
    const fetchLocationAndPrayerTimes = async () => {
      try {
        // Fetch location data
        const locationResponse = await fetch('https://pro.ip-api.com/json/?key=UNZGNB1ewdjlcde');
        const locationData = await locationResponse.json();

        if (!locationResponse.ok) {
          throw new Error('Location data not available');
        }

        console.log(locationData);
        const { city, country, lat, lon } = locationData;
        setLocationData({ city, country, lat, lon });
        setSelectedCity(city);

        // Fetch prayer times for the detected city
        await fetchPrayerTimes(city, country);

        // Fetch nearby mosques
        await fetchNearbyMosques(lat, lon);
      } catch (error) {
        console.error('Error in fetchLocationAndPrayerTimes:', error);
        setError('Could not retrieve location data or prayer times.');
      } finally {
        setLoading(false);
      }
    };

    fetchLocationAndPrayerTimes();
  }, []);

  useEffect(() => {
    if (selectedCity && locationData.country) {
      fetchPrayerTimes(selectedCity, locationData.country);
    }
  }, [dateParam, selectedCity, locationData.country]);

  useEffect(() => {
    let timer;
    if (data) {
      timer = setInterval(() => {
        updateCountdown();
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [data]);

  const fetchPrayerTimes = async (city, country) => {
    try {
      const apiUrl = `https://api.aladhan.com/v1/calendarByCity/${currentYear}/${currentMonth}?city=${city}&country=${country}`;
      const response = await axios.get(apiUrl);
      if (response.status === 200) {
        const prayerTimes = response.data.data;
        const prayerTimesForDate = prayerTimes.find(item => item.date.gregorian.date === dateParam);
        setData(prayerTimesForDate || null);
        updateCountdown();
      } else {
        throw new Error('Could not retrieve prayer times.');
      }
    } catch (error) {
      console.error('Error fetching prayer times:', error);
      setError('Could not retrieve prayer times for the selected date.');
    }
  };

  const fetchNearbyMosques = async (latitude, longitude) => {
    try {
      const apiUrl = `https://overpass-api.de/api/interpreter?data=[out:json];node["amenity"="place_of_worship"]["religion"="muslim"](around:5000,${latitude},${longitude});out;`;
      const response = await axios.get(apiUrl);
      if (response.status === 200) {
        setMosques(response.data.elements);
      } else {
        throw new Error('Could not retrieve nearby mosques.');
      }
    } catch (error) {
      console.error('Error fetching nearby mosques:', error);
      setError('Could not retrieve nearby mosques.');
    }
  };

  const updateCountdown = () => {
    if (!data || !data.timings) return;

    const now = new Date();
    const prayers = [
      { name: 'Fajr', time: data.timings.Fajr },
      { name: 'Dhuhr', time: data.timings.Dhuhr },
      { name: 'Asr', time: data.timings.Asr },
      { name: 'Maghrib', time: data.timings.Maghrib },
      { name: 'Isha', time: data.timings.Isha },
    ];

    const currentDate = parseISO(dateParam.split('-').reverse().join('-'));
    
    const nextPrayer = prayers.find(prayer => {
      const [hours, minutes] = prayer.time.split(':');
      const prayerTime = new Date(currentDate);
      prayerTime.setHours(parseInt(hours, 10), parseInt(minutes, 10), 0, 0);
      return isBefore(now, prayerTime);
    });

    if (nextPrayer) {
      const [hours, minutes] = nextPrayer.time.split(':');
      const nextPrayerTime = new Date(currentDate);
      nextPrayerTime.setHours(parseInt(hours, 10), parseInt(minutes, 10), 0, 0);
      
      const diffInSeconds = differenceInSeconds(nextPrayerTime, now);
      setCountdown(diffInSeconds);
      setNextPrayer(nextPrayer.name);
    } else {
      // If all prayers have passed, set next prayer to tomorrow's Fajr
      const tomorrowDate = addDays(currentDate, 1);
      const [fajrHours, fajrMinutes] = data.timings.Fajr.split(':');
      const tomorrowFajr = new Date(tomorrowDate);
      tomorrowFajr.setHours(parseInt(fajrHours, 10), parseInt(fajrMinutes, 10), 0, 0);
      
      const diffInSeconds = differenceInSeconds(tomorrowFajr, now);
      setCountdown(diffInSeconds);
      setNextPrayer('Fajr (Tomorrow)');
    }
  };

  const formatCountdown = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const handleDateChange = (newDate) => {
    const newDateObj = new Date(newDate.split('-').reverse().join('-'));
    const newMonth = newDateObj.getMonth() + 1;
    const newYear = newDateObj.getFullYear();

    if (newMonth !== currentMonth || newYear !== currentYear) {
      setCurrentMonth(newMonth);
      setCurrentYear(newYear);
    }
    setDateParam(newDate);
    navigate(`?date=${newDate}`);
  };

  const handleCityChange = (event) => {
    const newCity = event.target.value;
    setSelectedCity(newCity);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="container mt-4">
      <div className="card">
        <h1>Prayer Times for {selectedCity}, {locationData.country}</h1>
        <div className="mb-3">
          <label htmlFor="citySelect" className="form-label">Select City:</label>
          <select
            id="citySelect"
            className="form-select"
            value={selectedCity}
            onChange={handleCityChange}
          >
            <option value="">Select a city</option>
            {moroccoCities.map(city => (
              <option key={city.name} value={city.name}>
                {city.name}
              </option>
            ))}
          </select>
        </div>
        {data === null ? (
          <p>Could not retrieve prayer times for the selected date. Please try again later.</p>
        ) : (
          <>
            <div className="mt-3 text-center">
              <h2>Next Prayer: {nextPrayer}</h2>
              <h3>Time Remaining: {countdown !== null ? formatCountdown(countdown) : 'Calculating...'}</h3>
            </div>
            <div className="prayer-times">
              <div className="success">Date: {data.date?.readable || 'N/A'}</div>
                <div className={nextPrayer === 'Fajr' ? 'red-border' : ''}>Fajr: {data.timings?.Fajr || 'N/A'}</div>
                <div className={nextPrayer === 'Sunrise' ? 'red-border' : ''}>Sunrise: {data.timings?.Sunrise || 'N/A'}</div>
                <div className={nextPrayer === 'Dhuhr' ? 'red-border' : ''}>Dhuhr: {data.timings?.Dhuhr || 'N/A'}</div>
                <div className={nextPrayer === 'Asr' ? 'red-border' : ''}>Asr: {data.timings?.Asr || 'N/A'}</div>
                <div className={nextPrayer === 'Maghrib' ? 'red-border' : ''}>Maghrib: {data.timings?.Maghrib || 'N/A'}</div>
                <div className={nextPrayer === 'Isha' ? 'red-border' : ''}>Isha: {data.timings?.Isha || 'N/A'}</div>
            </div>
            <div className="mt-3">
              <h2>Nearby Mosques</h2>
              {mosques.length > 0 ? (
                <div id="mosqueCarousel" className="carousel slide">
                  <div className="carousel-inner">
                    {mosques.map((mosque, index) => (
                      <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={mosque.id}>
                        <div className="d-block w-100 p-3">
                          <h3>{mosque.tags.name || 'Unnamed Mosque'}</h3>
                          <p>{`Latitude: ${mosque.lat}, Longitude: ${mosque.lon}`}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                  <a className="carousel-control-prev" href="#mosqueCarousel" role="button" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="false"></span>
                    <span className="visually-hidden">Previous</span>
                  </a>
                  <a className="carousel-control-next" href="#mosqueCarousel" role="button" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                  </a>

                  
                </div>
              ) : (
                <p>No nearby mosques found.</p>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PrayerTimes;
