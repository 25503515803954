import React, { useState,useEffect } from 'react';
import axios from 'axios';




const CreatePersonForm = () => {


const [people, setPeople] = useState([]);


// axios of fetch use to handle http request 


useEffect(() => {

    axios.get('https://webview.orange-maroc.net/api/getall')
        .then(response => {
            setPeople(response.data);
        });

}, []);






  const [formData, setFormData] = useState({
    name: '',
    age: '',
    phone: '',
    email: ''
  });






  const handleChange = (e) => {
    const { name, value } = e.target;setFormData({...formData,[name]: value});
  };





  const handleSubmit = (e) => {
    e.preventDefault();

    // Send POST request to the API
    fetch('https://webview.orange-maroc.net/api/create_people', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    })
    .then(response => response.json())
    .then(data => {
      console.log('Success:', data);
      // Handle success (e.g., show a success message or clear the form)
    })
    .catch(error => {
      console.error('Error:', error);
      // Handle error (e.g., show an error message)
    });
  };



















  return (
    <form onSubmit={handleSubmit}>
      <div>
      <div>
            <h2>People List</h2>
            <ul>
                {people.map(person => (
                    <li key={person.id}>
                        {person.name} - {person.email}
                        {/* <button onClick={() => deletePerson(person.id)}>Delete</button> */}
                    </li>
                ))}
            </ul>
        </div>,

        <label>Name:</label>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <label>Age:</label>
        <input
          type="number"
          name="age"
          value={formData.age}
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <label>Phone:</label>
        <input
          type="text"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <label>Email:</label>
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />
      </div>
      <button type="submit">Submit</button>
    </form>

    
  );
};

export default CreatePersonForm;
