import React from 'react';

const Pharmacie = () => {
  return (
    <div>
      <h1>pharmacie Page</h1>
      <p>Get in touch with us!</p>
    </div>
  );
};

export default Pharmacie;
