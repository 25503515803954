import React, { useEffect, useState } from 'react';

const IpInfo = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://pro.ip-api.com/json/?key=UNZGNB1ewdjlcde');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        setData(result);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div>
      <h1>IP Information</h1>
      {data && (
        <ul>
          <li><strong>IP:</strong> {data.query}</li>
          <li><strong>City:</strong> {data.city}</li>
          <li><strong>Region:</strong> {data.regionName}</li>
          <li><strong>Country:</strong> {data.country}</li>
          <li><strong>ISP:</strong> {data.isp}</li>
        </ul>
      )}
    </div>
  );
};

export default IpInfo;
