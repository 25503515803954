import React, { useState, useEffect } from 'react';
import axios from 'axios';

const PersonList = () => {
    const [people, setPeople] = useState([]);

    useEffect(() => {
        axios.get('https://webview.orange-maroc.net/api/getall')
            .then(response => {
                setPeople(response.data);
            });
    }, []);

    // const deletePerson = (id) => {
    //     axios.delete(`https://webview.orange-maroc.net/api/deletepepole/${id}`)
    //         .then(() => {
    //             setPeople(people.filter(person => person.id !== id));
    //         });
    // };

    const deletePerson = (id) => {
        // Display a confirmation alert
        const confirmDelete = window.confirm("Are you sure you want to delete this person?");
    
        // If the user confirms, proceed with deletion
        if (confirmDelete) {
            axios.delete(`https://webview.orange-maroc.net/api/deletepepole/${id}`)
                .then(() => {
                    // Update the state to remove the deleted person from the list
                    setPeople(people.filter(person => person.id !== id));
                })
                .catch((error) => {
                    console.error("There was an error deleting the person!", error);
                });
        }
    };
    

    return (
        <div>
            <h2>People List</h2>
            <ul>
                {people.map(person => (
                    <li key={person.id}>
                        {person.name} - {person.email}
                        <button onClick={() => deletePerson(person.id)}>Delete</button>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default PersonList;
