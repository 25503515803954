import React from 'react';
import { Link } from 'react-router-dom';
import './Sidebar.css'; // Optional: For styling your sidebar

const Sidebar = () => {
  return (
    <aside className="sidebar">
      <ul>
        <li><Link to="/">Home</Link></li>
        <li><Link to="/about">About</Link></li>
        <li><Link to="/contact">Contact</Link></li>
        <li><Link to="/sallat">Sallat</Link></li>
        <li><Link to="/asn">asn</Link></li>
        <li><Link to="/asn2">asn2</Link></li>
        <li><Link to="/Sallat3">Sallat3</Link></li>
        <li><Link to="/Sallat4">Sallat4</Link></li>
        <li><Link to="/Sallat5">Sallat5</Link></li>
        <li><Link to="/Sallat6">Sallat6</Link></li>
        <li><Link to="/Sallat7">Sallat7</Link></li>
        <li><Link to="/Sallat8">Sallat8</Link></li>
        <li><Link to="/Sallat9">Sallat9</Link></li>
        <li><Link to="/consomationapi">Consomationapi</Link></li>
        <li><Link to="/Consomationapiusigservices">Consomationapi2</Link></li>
        <li><Link to="/Meteo">Meteo</Link></li>
        <li><Link to="/Weather">Weather</Link></li>
        <li><Link to="/Weather2">Weather2</Link></li>
        <li><Link to="/Hooks">Hooks</Link></li>
        <li><Link to="/Crud">Crud</Link></li>
        <li><Link to="/Addperson">Addperson</Link></li>
        <li><Link to="/Dash">Dash</Link></li>
        <li><Link to="/Sallat2api">Sallat2api</Link></li>








        {/* Add more links as needed */}
      </ul>
    </aside>
  );
};

export default Sidebar;
