import React from 'react';
import { Link } from 'react-router-dom';
import './Sidebar.css'; // Optional: For styling your sidebar

const Sidebar = () => {
  return (
    <aside className="sidebar">
      <ul>
        <li><Link to="/">Home</Link></li>
        <li><Link to="/about">About</Link></li>
        <li><Link to="/contact">Contact</Link></li>
        <li><Link to="/sallat">Sallat</Link></li>
        <li><Link to="/asn">asn</Link></li>
      </ul>
    </aside>
  );
};

export default Sidebar;
