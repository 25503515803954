import React, { useState } from 'react';
import axios from 'axios';

const Weather = () => {
  const [weatherData, setWeatherData] = useState(null);
  const [city, setCity] = useState('');

  const apiKey = '0f5ad6c1d14ca595ddf930be1315db15';

  const getWeather = async () => {
    try {
      const response = await axios.get(
        `http://api.weatherstack.com/current?access_key=${apiKey}&query=${city}`
      );
      setWeatherData(response.data);
    } catch (error) {
      console.error('Error fetching the weather data', error);
    }
  };

  const handleInputChange = (e) => {
    setCity(e.target.value);
  };

  return (
    <div>
      <h1>Weather App</h1>
      <input
        type="text"
        placeholder="Enter city"
        value={city}
        onChange={handleInputChange}
      />
      <button onClick={getWeather}>Get Weather</button>

      {weatherData && weatherData.current && (
        <div>
          <h2>{weatherData.location.name}, {weatherData.location.country}</h2>
          <p>Temperature: {weatherData.current.temperature}°C</p>
          <p>Weather: {weatherData.current.weather_descriptions[0]}</p>
          <p>Wind: {weatherData.current.wind_speed}</p>
          <p>Precip: {weatherData.current.precip}</p>
          <p>Pressure: {weatherData.current.pressure}</p>
        </div>
      )}
    </div>
  );
};

export default Weather;
